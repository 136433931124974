import { useState, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import {
    DEFAULT_ASSET_CATEGORY,
    DEFAULT_ORDER,
    DEFAULT_ASSET_TIMEFRAME,
    PageSizes,
} from 'utils/constants'
import { DEFAULT_ASSETS_SORT_BY } from '../constants'
import { getParamsFromQuery } from './utils'
import { usePaginatedAssetsForArchiveAppended } from 'data/paginationQueries'

export function useParamsFromUrl(
    page = '',
    query = {
        page: DEFAULT_ASSET_CATEGORY.key,
        sort: DEFAULT_ASSETS_SORT_BY.key,
        timeframe: DEFAULT_ASSET_TIMEFRAME.key,
        order: DEFAULT_ORDER.key,
        byChange: false,
        search: '',
    }
) {
    const router = useRouter()

    const [params, setParams] = useState(getParamsFromQuery(query))

    const onSetParams = newParams => {
        const searchQuery = newParams?.search
            ? {
                  search: newParams?.search,
              }
            : {}
        const newQuery = {
            sort: newParams?.sortBy?.key,
            timeframe: newParams?.timeframe?.key,
            order: newParams?.order?.key,
            byChange: newParams?.byChange,
            ...searchQuery,
        }

        router.replace(
            `/assets/${newParams?.category?.key}`,
            {
                pathname: `/assets/${newParams?.category?.key}`,
                query: newQuery,
            },
            {
                shallow: false,
                scroll: false,
            }
        )
        setParams({ ...newParams })
    }

    return [params, onSetParams]
}

// use to fetch pages with appending
export const useAssetsData = () => {
    const { paginationParams, queryParams } = useTableArchiveContext()

    const categoryKey = queryParams?.category?.key
    const sortByKey = queryParams?.sortBy?.key
    const orderKey = queryParams?.order?.key
    const timeframeKey = queryParams?.timeframe?.key
    const byChange = queryParams?.byChange
    const searchTerms = queryParams?.search || ''

    const page = Number(paginationParams?.page ?? 0)
    const pageSizeNumber = Number(
        paginationParams?.pageSize?.key ?? PageSizes.Twenty
    )

    // Get 7 (+1) days for the bar chart
    const weekAgoDate = useMemo(() => {
        return new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10)
    }, [])

    const { data, size, setSize, isValidating } =
        usePaginatedAssetsForArchiveAppended(
            {
                search: searchTerms,
                category: categoryKey,
                sortBy: sortByKey,
                order: orderKey,
                timeframe: timeframeKey,
                byChange,
                weekAgoDate,
            },
            pageSizeNumber + 1,
            true
        )

    useEffect(() => {
        // NB: SWR's size (page number) starts from 1
        if (page + 1 !== size) {
            setSize(page + 1)
        }
    }, [page, setSize, size])

    const lastBatch = data?.[(data?.length ?? 0) - 1]
    const hasMore = (lastBatch?.assets?.length ?? 0) > pageSizeNumber
    const noDataFound = !Boolean(
        data?.map(batch => batch?.assets ?? [])?.flat()?.length
    )

    // Since we fetched pageSizeNumber + 1 items (for hasMore check), remove the last item
    const batches = data?.map(batch => ({
        assets: batch?.assets?.slice(0, pageSizeNumber) ?? [],
        assetsWithMetrics:
            batch?.assetsWithMetrics?.slice(0, pageSizeNumber) ?? [],
    }))
 
    return {
        data: batches,
        isLoading: isValidating,
        noDataFound,
        isEnd: !hasMore,
    }
}
